import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { CheckOutlined } from '@ant-design/icons';
import { A } from 'atoms';
import { colors } from 'styles';

import { StreamVisualization } from 'libraries/Media';

export const useDeviceList = () => {

    const [loaded, setLoaded] = useState(false);
    const [audioDeviceList, setAudioDeviceList] = useState([]);
    const [videoDeviceList, setVideoDeviceList] = useState([]);

    useEffect(() => {
        try {
            window.navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const _audioDeviceList = devices.filter(d => d.kind === 'audioinput');
                    setAudioDeviceList(_audioDeviceList);
                    const _videoDeviceList = devices.filter(d => d.kind === 'videoinput');
                    setVideoDeviceList(_videoDeviceList);
                    setLoaded(true);
                })
                .catch((err) => {
                    console.log(err.name + ": " + err.message);
                });
            return () => {
            }
        } catch (e) {
            console.error("Failed to get device list", e);
        }
    }, []);
    return {
        loaded,
        audioDeviceList,
        videoDeviceList
    };
};

const StyledDeviceList = styled.div``;
const StyledDeviceItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    :last-child {
        margin-bottom: 0;
    }
    :hover {
        color: black;
    }
`;
const DeviceLabel = styled.div`
    max-width: 200px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const beautyLabel = (label) => {
    return label;
}

const DeviceGroup = styled.div`
    padding: 20px;
    border-bottom: 1px solid var(--color-border);
    ${props => props.css}
`;

const GroupTitle = styled.div`
    font-weight: 500;
    margin-bottom: 10px;
`;

const GroupContent = styled.div`
`;

const VisualizationContainer = styled.div`
    max-width: 50px;
    margin: 0 auto;
`;

const DeviceItem = ({ label, selected, onSelect }) => {
    return (
        <StyledDeviceItem onClick={() => onSelect()} selected={selected}>
            <DeviceLabel>
                {label}
            </DeviceLabel>
            {
                selected && <CheckOutlined style={{ paddingLeft: '10px', fontSize: '20px', color: colors.green }} />
            }
        </StyledDeviceItem>
    )
}

const DeviceList = ({
    stream,
    selectedAudioId: $selectedAudioId,
    selectedVideoId: $selectedVideoId,
    setSelectedAudioId,
    setSelectedVideoId
}) => {
    const { loaded, audioDeviceList, videoDeviceList } = useDeviceList();

    const selectedDevices = useMemo(() => {
        if (stream) {
            const tracks = stream.getTracks();
            return tracks.map(track => track.getSettings().deviceId);
        }
        return [];
    }, [stream]);

    const [_selectedVideoId, _setSelectedVideoId] = useState(null);
    const [_selectedAudioId, _setSelectedAudioId] = useState(null);

    const selectedVideoId = useMemo(() => {
        if ($selectedVideoId) {
            return $selectedVideoId;
        }
        for (const device of videoDeviceList) {
            if (selectedDevices.includes(device.deviceId)) {
                return device.deviceId;
            }
        }
        return _selectedVideoId;
    }, [selectedDevices, videoDeviceList, $selectedVideoId, _selectedVideoId]);

    const selectedAudioId = useMemo(() => {
        if ($selectedAudioId) {
            return $selectedAudioId;
        }
        for (const device of audioDeviceList) {
            if (selectedDevices.includes(device.deviceId)) {
                return device.deviceId;
            }
        }
        return _selectedAudioId;
    }, [selectedDevices, audioDeviceList, $selectedAudioId, _selectedAudioId]);

    const audioConstraints = useMemo(() => (selectedAudioId ? { deviceId: selectedAudioId } : true), [selectedAudioId]);
    const constraints = useMemo(() => ({
        audio: audioConstraints
    }), [audioConstraints]);

    useEffect(() => {
        if (selectedVideoId) {
            _setSelectedVideoId(selectedVideoId);
        }
    }, [selectedVideoId]);

    useEffect(() => {
        if (selectedAudioId) {
            _setSelectedAudioId(selectedAudioId);
        }
    }, [selectedAudioId]);

    return (
        <StyledDeviceList>
            <DeviceGroup>
                <GroupTitle>Select a Camera</GroupTitle>
                <GroupContent>
                    {
                        videoDeviceList.length === 0 && (
                            'No camera connected'
                        )
                    }
                    {
                        videoDeviceList.map(({ deviceId, label }) =>
                            <DeviceItem
                                label={label}
                                key={deviceId}
                                selected={selectedVideoId === deviceId}
                                onSelect={() => setSelectedVideoId(deviceId)}
                            />
                        )
                    }
                </GroupContent>
            </DeviceGroup>
            <DeviceGroup>
                <GroupTitle>Select a Microphone</GroupTitle>
                <GroupContent>
                    {
                        audioDeviceList.map(({ deviceId, label }) =>
                            <DeviceItem
                                label={label}
                                key={deviceId}
                                selected={selectedAudioId === deviceId}
                                onSelect={() => setSelectedAudioId(deviceId)}
                            />
                        )
                    }
                </GroupContent>
            </DeviceGroup>
            <DeviceGroup css={`padding: 5px 10px 10px;`}>
                <VisualizationContainer>
                    <StreamVisualization height={30} constraints={constraints} stream={stream} />
                </VisualizationContainer>
            </DeviceGroup>
        </StyledDeviceList>
    )
}

export default DeviceList;
