import { gql } from "@apollo/client";
import { messageFields, replyFields } from "graphql-api";

export const INBOX_MESSAGES_QUERY = gql`
  query inboxMessages(
    $inboxId: ID
    $limit: Int
    $page: Int
    $private: Boolean
    $pinned: Boolean
    $replied: Boolean
    $tags: [ID!]
    $showId: ID
    $showSlug: String
  ) {
    inboxMessages(
      inboxId: $inboxId
      limit: $limit
      page: $page
      private: $private
      pinned: $pinned
      replied: $replied
      tags: $tags
    ) {
      collection {
        ...messageFields
        reply {
          ...replyFields
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
  ${messageFields}
  ${replyFields}
`;

export const MESSAGE_QUERY = gql`
  query message($id: ID!, $showId: ID, $showSlug: String) {
    message(id: $id) {
      ...messageFields
        reply {
          ...replyFields
        }
    }
  }
  ${messageFields}
  ${replyFields}
`;

export const REPLY_QUERY = gql`
  query reply($id: ID!) {
    reply(id: $id) {
      ...replyFields
    }
  }
  ${replyFields}
`;
